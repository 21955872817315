import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Introduction from "../components/introduction"
import {Cursor, CursorStep} from "../components/cursor"
import Content from './index.yml'

const IndexPage = () => {

  let initCursor = null;

  let alreadyVisited = false;
  if (typeof window !== 'undefined') {
    alreadyVisited = sessionStorage.alreadyVisited;
  }

  const onAnimationEnd = () => {
    initCursor();
  }

  function cursorReady(startH) {
    if(initCursor)startH.init();
    initCursor = startH.init;
  }

  function RenderIntroduction(props) {
    if(props.alreadyVisited === undefined) {      
      return (<div>{props.children}</div>)
    }

    initCursor = true;
    return (<div></div>)
  }

  setTimeout(() => {
    
    document
    .querySelector("#menu")
    .querySelectorAll("li").forEach(li => {
      li.classList.remove('current')
    })
    
    document
    .querySelector("#menu")
    .querySelectorAll("li")[0]
    .classList.add("current");
  }, 2000)

  return (
    <Layout>
      <SEO title={Content.title} />
      <RenderIntroduction alreadyVisited={alreadyVisited}>
        <Introduction onAnimationEnd={onAnimationEnd} />
      </RenderIntroduction>
      
      <div className="content home">
        <Cursor cursorReady={startH => cursorReady(startH)}>
          {Content.content.map( ({step}, index) => {
            return <CursorStep key={`cursor-step-${index}`} index={index} content={step} />
          })}
        </Cursor>
      </div>
    </Layout>
  )
}

export default IndexPage
