import React from 'react'
import Icon from '../images/pcx-avocats.svg'

const Introduction = ({onAnimationEnd}) => {
    
    setTimeout(() => {
        if(document.getElementById('introduction')) {
            document.getElementById('introduction').remove();
            if(onAnimationEnd) {
                onAnimationEnd();
            }
        }
    }, 5000)

    return (
        <div id="introduction" className="show">
            <Icon/>
        </div>
    )
}

export default Introduction